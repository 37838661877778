<style lang="scss" scoped>
.assured_book {
	height: 100vh;
	box-sizing: border-box;
	padding: 0 0.1rem;
	display: flex;
	flex-direction: column;
	.s0 {
		flex-shrink: 0;
	}
	.s1 {
		flex: 1;
	}
}
.header {
	margin-top: 0.1rem;
	font-size: 0.3rem;
	text-align: center;
	padding: 0 0.3rem;
}
.list {
	font-size: 0.26rem;
	text-align: center;
	overflow: auto;
}
::v-deep .list .van-col {
	text-align: center;
}
.footer {
	text-align: center;
	padding: 0.3rem;
}
</style>

<template>
	<div class="assured_book">
		<header-nav class="s0" :hasLogout="false">常用被保险人</header-nav>
		<div class="header s0">
			<van-row>
				<van-col span="4">姓名</van-col>
				<van-col span="10">身份证</van-col>
				<van-col span="4">职业</van-col>
				<van-col span="6"></van-col>
			</van-row>
		</div>
		<!-- <van-divider class="s0"></van-divider> -->
		<section class="s1 list">
			<van-list>
				<van-cell v-for="(item, index) in list" :key="index">
					<van-row type="flex" align="center">
						<van-col span="4" v-text="item.name">姓名</van-col>
						<van-col span="10" v-text="item.certificateContent">身份证</van-col>
						<van-col span="4" v-text="item.profName">职业</van-col>
						<van-col span="6">
							<van-button size="small" @click="add(item)">添加</van-button>
						</van-col>
					</van-row>
				</van-cell>
			</van-list>
		</section>
		<!-- <van-divider class="s0"></van-divider> -->
		<div class="footer s0">
			<van-button type="info" block @click="back">关闭</van-button>
		</div>
	</div>
</template>

<script>
import headerNav from './headerNav.vue';
import { Divider, Col, Row, List, Cell, Button, Toast } from 'vant';
import { http_getAssured } from '@/request/sundry';
import bus from '@/components/bus';

export default {
	name: 'assuredBook2', // 常用被保险人
	components: {
		headerNav,
		[Divider.name]: Divider,
		[Col.name]: Col,
		[Row.name]: Row,
		[List.name]: List,
		[Cell.name]: Cell,
		[Button.name]: Button,
		[Toast.name]: Toast,
	},
	data() {
		return {
			list: [],
		};
	},
	created() {
		this.getAssureds();
	},
	methods: {
		back() {
			this.$router.go(-1);
		},

		add(info) {
			let assured = {
				userType: '',
				insureRelation: info.relation,
				userName: info.name,
				cardType: info.certificateType,
				cardNum: info.certificateContent,
				birthday: info.birthday,
				sex: info.sex,
				phone: info.mobile,
				email: info.assEmail,
				buyNum: info.buyNum,
				job: info.profName,
				jobDict: {
					profCode: info.profCode,
					parentCode: info.parentCode,
					codeData: info.job,
					profName: info.profName,
				},
			};
			bus.$emit('addUser3', assured);
			Toast({
				message: '添加成功',
				type: 'success',
				duration: 200,
			});
		},

		// 查询常用被保险人
		getAssureds() {
			let send = {
				page: 0,
				limit: 1000,
			};
			http_getAssured(send).then(res => {
				// console.log(res);
				this.list = res.rows;
			});
		},
	},
};
</script>
